@use 'src/main';

.header__container {
  display: grid;
  grid-template-rows: 1fr 3fr 1fr;
  background: url('../assets/header.svg') no-repeat;
  background-size: cover;
}

.page-header {
  flex-grow: 0;
  flex-shrink: 0;
  height: main.pxToRem(40px);
  padding: main.pxToRem(26px) main.pxToRem(52px);

  display: flex;
  align-items: center;

  background-color: #457D7D;
  opacity: 88%;
  gap: main.pxToRem(52px);

  .page-header__title {
    font-size: main.pxToRem(56px);
    letter-spacing: main.pxToRem(3px);
    text-shadow: 0 main.pxToRem(4px) main.pxToRem(4px) rgba(0, 0, 0, 0.25);
    align-self: flex-start;
    margin: 0;
  }

  .page-header__copy-link-button {
    font-size: main.pxToRem(22px);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 6px 12px 6px 12px;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        padding-left: main.pxToRem(13px);
      }
    }
    .MuiSvgIcon-root {
      font-size: main.pxToRem(48px);
    }

    &:focus-visible,
    &:hover {
      outline: 3px solid white;
    }

    &:active {
      background-color: #3a5959;
    }
  }

  .page-header__back-button {
    color: white;
    background-color: #AA35AA;
    outline: #ECC5EC solid main.pxToRem(5px);
    box-shadow: 0 main.pxToRem(4px) main.pxToRem(4px) #003435;
    stroke: white;
    height: main.pxToRem(64px);
    width: main.pxToRem(64px);

    .MuiSvgIcon-root {
      font-size: main.pxToRem(52px);
    }

    &:focus-visible,
    &:hover {
      outline: white solid main.pxToRem(5px);
    }

    &:active {
      background-color: #690069;
    }
  }
}
