@use '../../main.scss';

.avatar {
  font-family: 'Patrick Hand', sans-serif;
  color: #000000;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px; //Ties directly to the sign rectangle in each avatar
    height: 115px; //Ties directly to the sign rectangle in each avatar
    p {
      margin: 5px;
      word-wrap: break-word;
      font-size: 20px;
      text-align: center;
    }
  }
}
