@use 'src/main';

.sidebar {
    position: fixed;
    left: 0;
    top: main.pxToRem(300px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 main.pxToRem(3px) main.pxToRem(10px) 0 #003435;
  
  button {
    height: main.pxToRem(150px);
    width: main.pxToRem(100px);
    background-color: #508D8C;
    color: #FFF;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: main.pxToRem(20px);
    line-height: main.pxToRem(23px);
    border: none;
    outline: none;
    padding-bottom: main.pxToRem(12px);

    svg {
      width: main.pxToRem(48px);
      aspect-ratio: auto;
    }
    
    &:hover {
      cursor: pointer;
      border: main.pxToRem(4px) solid #FFF;
    }
    
    &:focus-visible {
      border: main.pxToRem(4px) solid #FFF;
    }

    &:active {
      background-color: #3a5959;
    }
    
    &:disabled {
      cursor: not-allowed;
      border: none;
      opacity: 50%;

      &:active {
        background-color: #508D8C;
      }
  }
  }
}