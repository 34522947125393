@use 'src/main';

.participants.voters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: main.pxToRem(10px);

    .team-votes__kick-button svg {
        height: main.pxToRem(30px);
        width: main.pxToRem(30px);
    }

    svg {
        height: main.pxToRem(320px);
        width: main.pxToRem(200px);
    }
}

.team-votes__vote-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: main.pxToRem(50px);

    .character-counter {
        align-self: flex-start;
    }
}

.team-votes_vote-section {
    display: flex;
    flex-direction: row;
}

.team-votes_info-tooltip {
    align-self: center;
    padding: main.pxToRem(main.$figmaFontSize / 2);
}

.team-votes__edit-topic-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: main.pxToRem(16px);
    margin-top: main.pxToRem(16px);
    margin-bottom: main.pxToRem(50px);

    div {
        display: flex;
        flex-direction: column;
        gap: main.pxToRem(10px);
    }
}

.team-votes__topic {
    padding-top: main.pxToRem(30px);
    padding-bottom: main.pxToRem(10px);
}

.team-votes__button-row {
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: main.pxToRem(16px);

    textarea {
        width: 100%;
        font-size: main.pxToRem(24px);
        padding-block: main.pxToRem(28px);
        padding-inline: main.pxToRem(20px);
    }
}

.team-votes__edit-topic-button {
    margin-bottom: main.pxToRem(50px) !important;
}

.non-voting-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .not-voting-label {
        font-size: main.pxToRem(32px);
        letter-spacing: main.pxToRem(1px);
    }

    .participants {
        margin-top: main.pxToRem(-50px);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: main.pxToRem(10px);
    }

    .participants label {
        font-size: main.pxToRem(28px);
    }

    .team-votes__kick-button svg {
        height: main.pxToRem(30px);
        width: main.pxToRem(30px);
    }

    svg {
        height: main.pxToRem(140px);
        width: main.pxToRem(140px);
    }
}
