@use 'src/main';

.select-avatar__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    row-gap: main.pxToRem(50px);

    .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: main.pxToRem(15px);
    }

    .select-avatar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: main.pxToRem(-70px);
    }

    .select-avatar__input {
        margin-top: main.pxToRem(20px);
    }

    .select-avatar__voter-radios {
        display: flex;
        width: 100%;
        color: #690069;

        label {
            font-family: Roboto, sans-serif;
            font-weight: lighter;
            font-size: main.pxToRem(20px);
            display: flex;
            align-items: center;
            margin: 0 auto;

            input[type='radio'] {
                height: main.pxToRem(50px);
                width: main.pxToRem(50px);
                vertical-align: middle;
                margin-right: main.pxToRem(20px);
            }
        }

        :first-child {
            padding-left: 1.8em;
        }
    }

    .select-avatar__button {
        padding: 0;
        background: none;
        border: 1px solid transparent;

        svg {
            height: main.pxToRem(245px);
            width: main.pxToRem(144px);

            paint-order: stroke fill;

            &:hover {
                border: 1px solid white;
            }
        }
    }

    .disabled-wrapper {
        [disabled] {
            pointer-events: none;
        }
    }

    input.accent {
        accent-color: #AA35AA;
    }
}
