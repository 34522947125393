@use '../../main';

.create-room {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  .create-room__form {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    align-items: center;

    label {
      font-size: main.pxToRem(32px);
      paint-order: fill stroke;
    }

    input {
      width: 22rem;

      &.invalid {
        border: main.pxToRem(4px) solid #E74989;
        color: #E74989;
      }
    }

    .create-room__form__button-row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: main.pxToRem(20px);
      flex-wrap: wrap;
    }

    .MuiSvgIcon-root {
      border: main.pxToRem(4px) solid white;
      border-radius: main.pxToRem(36px);
      background-color: #E74989;
      color: white;
      padding: main.pxToRem(4px);
      height: main.pxToRem(main.$figmaFontSize);
      width: main.pxToRem(main.$figmaFontSize);
      position: relative;
      left: main.pxToRem(-60px);
      top: main.pxToRem(-54px);
    }
  }

  .create-room_howTo {
    text-align: center;
    background-color: #508D8C;
    align-self: stretch;
    padding: main.pxToRem(50px) 0;

    iframe {
      width: main.pxToRem(760px);
      height: main.pxToRem(428px);
      aspect-ratio: auto;
      border: 2px white solid;
    }
  }
}

.create-room__goals {
  align-content: start;
  margin-top: main.pxToRem(30px);
  margin-bottom: main.pxToRem(20px);

  h2 {
    font-size: main.pxToRem(40px);
    letter-spacing: main.pxToRem(3px);
    text-align: center;
    font-weight: normal;
    margin: 0;
  }
}

.create-room__description {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: main.pxToRem(20px);
  padding-bottom: main.pxToRem(80px);

  p {
    margin: 0;
  }
}

.create-room__helperText {
  font-family: "Roboto", sans-serif;
  font-size: main.pxToRem(20px);
  font-style: italic;

  height: main.pxToRem(40px);
}

.create-room__fruitAdvocates {
  display: none;
}

@media (min-width: 600px) and (min-height: 500px) {

  .create-room__fruitAdvocates {
    display: block;
    margin-top: main.pxToRem(20px);

    img {
      height: main.pxToRem(210px);
    }
  }

}


