@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

@font-face {
  font-family: 'FordAntenna';
  font-weight: 200;
  font-style: normal;
  src: url("assets/fonts/AntennaRE.woff") format("woff");
  font-display: swap;
}

$figmaFontSize: 26px;

$backgroundColor: #457D7D;
$fontColor: #FFFFFF;
$focusColor: #FFFFFF;

@function pxToRem($size) {
  @return math.div($size, $figmaFontSize) * 1rem;
}

body {
  margin: 0;
  font-family: 'Luckiest Guy', sans-serif;
}

html {
  font-size: CLAMP(10px, 2.4vh, 60px);
  color: white;
}

label {
  color: white;
  font-size: pxToRem(48px);
  letter-spacing: pxToRem(4px);
  paint-order: fill stroke;
}

.label--spaced {
  padding: pxToRem(50px) 0;
}

.screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input {
  height: pxToRem(80px);
  font-size: pxToRem(32px);
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  box-shadow: 0 pxToRem(4px) pxToRem(4px) rgba(0, 0, 0, 0.25);

  &:focus {
    outline: #B7D7D7 solid pxToRem(5px);
  }

  &:disabled {
    background-color: #CFCFCF;
    border: none;

    &:hover,
    &:active,
    &:focus {
      cursor: default;
    }
  }
}

textarea {
  font-size: pxToRem(24px);
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  box-shadow: 0 pxToRem(4px) pxToRem(4px) rgba(0, 0, 0, 0.25);

  resize: none;

  &:focus {
    outline: #B7D7D7 solid pxToRem(5px);
  }

  &:disabled {
    background-color: #CFCFCF;
    border: none;

    &:hover,
    &:active,
    &:focus {
      cursor: default;
    }
  }
}

@mixin button-defaults {
  height: pxToRem(70px);
  border: none;
  padding: pxToRem(8px) pxToRem(54px);
  margin: pxToRem(4px);
  letter-spacing: pxToRem(2px);
  font-size: pxToRem(28px);
  font-family: 'Luckiest Guy', sans-serif;
}

.button--primary {
  @include button-defaults;
  background-color: #AA35AA;
  color: white;
  box-shadow: 0 pxToRem(5px) pxToRem(20px) 0 #003435;
  outline: #ECC5EC solid pxToRem(3px);
  width: max-content;

  &:disabled {
    background-color: #D4D4D4;
    border: none;
    color: #457D7D;
    outline: #D4D4D4 solid pxToRem(3px);

    &:hover,
    &:active,
    &:focus {
      background-color: #D4D4D4;
      border: none;
      cursor: default;
      outline: solid #D4D4D4 pxToRem(3px);
    }
  }

  &:hover {
    cursor: pointer;
    outline: white solid pxToRem(5px);
  }

  &:focus {
    outline: white solid pxToRem(5px);
  }

  &:active {
    border: 0;
    background-color: #690069;
  }

  &.activeButton {
    border: 0;
  }
}

.button--secondary {
  @include button-defaults;
  background-color: #457d7d;
  color: white;
  outline: white solid pxToRem(3px);
  box-shadow: 0 pxToRem(5px) pxToRem(20px) 0 #003435;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    outline: white solid pxToRem(5px);
  }

  &:active {
    border: 0;
    outline: 0;
    color: #457d7d;
    background-color: #DEDEDE;
    box-shadow: 0 5px 20px 0 #003435, inset 3px 5px 10px #808080;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
}


.page-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;

  background-color: #457D7D;
  padding: 0 pxToRem(100px) 0 pxToRem(100px);
}

.character-counter {
  font-family: "Roboto", sans-serif;
  font-size: pxToRem(20px);
  margin-top: pxToRem(10px);
}
