@use 'src/main';

.MuiModal-root {

  h2,
  div div{
    background-color: #457d7d;
  }
}

.MuiDialogContent-root {
  width: 75%;
  margin: main.pxToRem(20px) auto 0;
  text-align: center;

  .MuiIconButton-root {
    position: absolute;
    right: main.pxToRem(16px);
    top: main.pxToRem(16px);
    color: white;
    background-color: #AA35AA;
    outline: 3px solid #ECC5EC;
    box-shadow: 0 4px 4px #003435;

    &:hover {
      outline: 5px solid white;
      background-color: #AA35AA;

      .MuiSvgIcon-root {
        stroke: white;
      }
    }
    &:active {
      border: 0;
      background-color: #690069;
    }
  }

  .MuiDialogContentText-root {
    font-family: Roboto, sans-serif;
    font-size: main.$figmaFontSize;
    color: white;
  }

  .MuiDialogActions-root {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: main.pxToRem(32px);

    button {
      @include main.button-defaults;
      font-family: 'Luckiest Guy', sans-serif;
      font-size: main.$figmaFontSize;
      margin-top: 15px;
      margin-left: 20px;
      margin-right: 20px;
      border-radius: 0;

      &.cancel {
        color: white;
        background-color: #457d7d;
        outline: 3px solid white;
        box-shadow: 0 5px 20px 0 #003435;

        &:hover {
          outline: 5px solid white;
        }

        &:active {
          border: 0;
          outline : 0;
          color: #457d7d;
          background-color: #DEDEDE;
          box-shadow: 0 5px 20px 0 #003435, inset 3px 5px 10px #808080;
        }
      }

      &.confirm {
        background-color: #AA35AA;
        color: white;
        outline: 3px solid #ECC5EC;
        box-shadow: 0 5px 20px 0 #003435;

        &:hover {
          outline: 5px solid white;
        }

        &:active {
          border: 0;
          background-color: #690069;
        }
      }
    }
  }
}

.team-votes__participant-name {
  font-size: main.pxToRem(32px);
  letter-spacing: main.pxToRem(1px);
}

.team-votes__kick-button {
  height: main.pxToRem(32px);
  width: main.pxToRem(32px);
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.team-votes__participant-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  .svg {
    outline: black solid main.pxToRem(10px);
  }
}
